import React from 'react'
import {RichText, Link } from 'prismic-reactjs'
import linkResolver from '../utils/linkResolver'
import PricingPanel from './pricingPanel'

import './pricingTabs.scss'

export function RichLink(link) {
  return Link.url(link, linkResolver);
}

function toggleTab(e){

  var prevActive = document.querySelector('.kb__tab--active');

  if(e.target === prevActive){
  }else{
    var panelToShow = e.target.getAttribute('data-show-panel');
    
    document.querySelector('.kb__panel--active').classList.remove('kb__panel--active');
    document.querySelector('[data-tab-panel="'+panelToShow+'"]').classList.add('kb__panel--active');

    prevActive.classList.remove('kb__tab--active');
    e.target.classList.add('kb__tab--active');
  }
  
}


const PricingTabs = (props) => {
  
  if(!props.tabs) return null;

  return (
    <section className="kb__pricing-tabs">
      <div className="kb__container--narrow">
        {props.tabs.primary.pricing_tabs_title && 
          <h3 className="kb--center">{RichText.asText(props.tabs.primary.pricing_tabs_title)}</h3>
        }
        <div className="kb__tabs">
          <div className="kb__pricing-tabs-header">
            {props.tabs.fields && 
              props.tabs.fields.map((tab, i) => {
                return(
                  <button key={i} onClick={toggleTab} data-show-panel={i} className={i === 1 ? 'kb__tab kb__tab--active' : 'kb__tab'}>{RichText.asText(tab.pricing_tab.pricing_name)}</button>
                )
              })
            }
          </div>

          <div className="kb__tabs-panels">

            {props.tabs.fields && 
              props.tabs.fields.map((panel, i) => {
                return(
                  <PricingPanel key={i} panel={panel} panel_to_show={i}/>
                )
              })
            }
          </div>
        </div>
      </div>
    </section>
  )
}

export default PricingTabs
