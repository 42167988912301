import React, {useState, useEffect} from 'react'


import CountUp from 'react-countup'
import './planCalculator.scss'

const PlanCalculator = () => {

  const [backerCount, setBackerCount] = useState(250);
  const [fundsRaised, setFundsRaised] = useState(50000);
  const [expectedFunds, setExpectedFunds] = useState(.15);

  // KB specifics
  const [tierSelection, setTierSelection] = useState("Balance");

  const [communityPrice, setCommunityPrice] = useState((backerCount * 0.4) + (0.13 * (fundsRaised * expectedFunds)));
  const [balancePrice, setBalancePrice] = useState((backerCount * 0.75) + (0.09 * (fundsRaised * expectedFunds)));
  const [elevatePrice, setElevatePrice] = useState((backerCount * 1.30) + (0.07 * (fundsRaised * expectedFunds)));

  const [displayPrice, setDisplayPrice] = useState(communityPrice);

  // Competitor specifics
  const [compSelection, setCompSelection] = useState('Basic');

  const [compBasicPrice, setCompBasicPrice] = useState((.02 * fundsRaised) + (.05 * (fundsRaised * expectedFunds)) + 199);
  const [compProPrice, setCompProPrice] = useState((.03 * fundsRaised) + 199);

  const [compDisplayPrice, setCompDisplayPrice] = useState(compBasicPrice);


  useEffect(() => {
 
    setCommunityPrice((backerCount * 0.4) + (0.13 * (fundsRaised * expectedFunds)));
    setBalancePrice((backerCount * 0.75) + (0.09 * (fundsRaised * expectedFunds)));
    setElevatePrice((backerCount * 1.30) + (0.07 * (fundsRaised * expectedFunds)));

    setCompBasicPrice((.02 * fundsRaised) + (.05 * (fundsRaised * expectedFunds)) + 199);
    setCompProPrice((.03 * fundsRaised) + 199);


    // Set range slider tooltip
    const range = document.getElementById('range');
    const rangeV = document.getElementById('rangeV');
    const setValue = ()=>{
      const newValue = Number( (range.value - range.min) * 100 / (range.max - range.min) );
      const newPosition = 10 - (newValue * 0.2);

      rangeV.innerHTML = `<span>${range.value}%</span>`;
      rangeV.style.left = `calc(${newValue}% + (${newPosition}px))`;
    };

    return(
      updateDisplay(),
      updateCompDisplay(),
      setValue()
    );
  });

  // const prices = {
  //   "community": {
  //     "rate": 0.13,
  //     "price": 0.4,
  //   },
  //   "balance": {
  //     "rate": 0.09,
  //     "price": 0.75,
  //   },
  //   "elevate": {
  //     "rate": 0.07,
  //     "price": 1.30,
  //   },
  //   "comp_pro": {
  //     "rate": .03,
  //     "fee": 199,
  //     "add_on": false,
  //   },
  //   "comp_basic": {
  //     "rate_1": .02,
  //     "rate_2": .05,
  //     "fee": 199,
  //     "add_on": true,
  //   }
  // }

  function setPricingCommunity(){
    setTierSelection('Community');
    updateDisplay();
  }

  function setPricingBalance(){
    setTierSelection('Balance');
    updateDisplay();
  }

  function setPricingElevate(){
    setTierSelection('Elevate');
    updateDisplay();
  }

  function setPricingBasic(){
    setCompSelection('Basic');
    updateCompDisplay();
  }

  function setPricingPro(){
    setCompSelection('Pro');
    updateCompDisplay();
  }

  function updateDisplay(){

    if(tierSelection === 'Community'){
      setDisplayPrice(communityPrice);
    }else if(tierSelection === 'Balance'){
      setDisplayPrice(balancePrice);
    }else if(tierSelection === 'Elevate'){
      setDisplayPrice(elevatePrice);
    }
  }

  function updateCompDisplay(){

    if(compSelection === 'Basic'){
      setCompDisplayPrice(compBasicPrice);
    }else if(compSelection === 'Pro'){
      setCompDisplayPrice(compProPrice);
    }
  }
  
  function checkNumber(e){

    if(e.target.value <= 0){
      e.target.value = 0;
    }
  }

  return(
    <div>
      
      <div className="kb__calc">
        <div className="kb__calc-inputs">
          <h4 className="purple  kb--center">Compare Pricing</h4>
          <label htmlFor="backers">Number of backers</label>
          <input id="backers" className="kb__calc-backers" type="number" min="0" defaultValue={backerCount} onChange={checkNumber} onInput={e => setBackerCount(e.target.value)} />
          <label htmlFor="funds">Amount of funds raised</label>
          <input id="funds" className="kb__calc-funds-raised" type="number" min="0" defaultValue={fundsRaised} onChange={checkNumber} onInput={e => setFundsRaised(e.target.value)}/>
          <label className="kb__expected-label">Expected funds raised through backer survey:</label>
          
          <div className="range-wrap">
		        <div className="range-value" id="rangeV"></div>
            <input name="foo" id="range" className="kb__calc-expected-funds" step="1" type="range" defaultValue={expectedFunds * 100} min="0" max="100" onInput={e => setExpectedFunds((e.target.value / 100))}></input>
          </div>

          <p className="kb__calc-info">The industry average is around 15% of total campaigns funds raised for projects that sell add-ons through their backer survey</p>
        </div>

        <div className="kb__grid">
          <div className="kb__col-6__sm-12">
            <div className="kb__calc-compare">

              <h4 className="purple kb--center">Kickbooster</h4>
              
              <div className="kb__calc-compare-buttons">
                <button className={`kb__calc-compare-button${tierSelection === "Community" ? " kb__calc-compare-button--active" : ""}`} onClick={setPricingCommunity}>Community</button>
                <button className={`kb__calc-compare-button${tierSelection === "Balance" ? " kb__calc-compare-button--active" : ""}`} onClick={setPricingBalance}>Balance</button>
                <button className={`kb__calc-compare-button${tierSelection === "Elevate" ? " kb__calc-compare-button--active" : ""}`} onClick={setPricingElevate}>Elevate</button>
              </div>

              <p className="big-body"><CountUp preserveValue={true} end={displayPrice} duration={.5} decimals={2} prefix="$" separator="," /><span className="currency">USD</span></p>
              <p className="smol-boi smol-boi--active">0% of campaign funds & shipping fees</p>
            </div>
          </div>

          <div className="kb__col-6__sm-12">
            <div className="kb__calc-compare">

              <h4 className="kb--center">Leading competitor</h4>

              <div className="kb__calc-compare-buttons">
                <button className={`kb__calc-compare-button${compSelection === "Basic" ? " kb__calc-compare-button--active" : ""}`} onClick={setPricingBasic}>Basic</button>
                <button className={`kb__calc-compare-button${compSelection === "Pro" ? " kb__calc-compare-button--active" : ""}`} onClick={setPricingPro}>Pro</button>
              </div>

              <p className="big-body"><CountUp preserveValue={true} end={compDisplayPrice} duration={.5} decimals={2} prefix="$" separator="," /><span className="currency">USD</span></p>

              <p className={`big-boi${compSelection === "Basic" ? " big-boi--active" : ""}`} >+ shipping charges</p>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default PlanCalculator;