import React, {useState} from 'react'
import {RichText, Link } from 'prismic-reactjs'
import linkResolver from '../utils/linkResolver'
import FAQs from './faqs'
import InlineSVG from './inlineSVG'
import IconCheck from '../images/check-circle-solid.svg'
import PlanCalculator from './planCalculator'

import './pricingPanel.scss'

export function RichLink(link) {
  return Link.url(link, linkResolver);
}

const PricingPanel = (props) => {

  const [pricingSelection, setPricingSelection] = useState("Balance");

  if(!props.panel) return null;

  var handledLink;

  if(props.panel.pricing_tab.price_cta){
    if(props.panel.pricing_tab.cta_internal_id){
      handledLink = RichLink(props.panel.pricing_tab.price_cta) +'#'+props.panel.pricing_tab.cta_internal_id;
    }else{
      handledLink = RichLink(props.panel.pricing_tab.price_cta)
    }
  }

  function setPricing(e){
    setPricingSelection(e.target.closest('.kb__price-tier').getAttribute('data-tier'));
  }

  function keyPress(e){
    // if the user is focused and hits enter
    if(e.keyCode === 13){
      setPricing(e);
    }
  }

  return (
    <div className={props.panel_to_show === 1 ? ' kb__pricing-panel kb__panel kb__panel--active' : 'kb__panel kb__pricing-panel'} data-tab-panel={props.panel_to_show}>
      <div className="kb__grid-center">
        
        <div className="kb__col-10_md-12">
          
          {props.panel.pricing_tab.pricing_title &&  
            <div className="kb__pricing-panel-blurb">
              {RichText.render(props.panel.pricing_tab.pricing_title)}
              {RichText.render(props.panel.pricing_tab.pricing_sub_title)}
            </div>
          }
          
          {props.panel.pricing_tab.body[0].type !== "price_tiers___calc" && 
            <div className="kb__price">
              <div className="kb__grid-center-middle">
                <div className="kb__price--1">
                  <h2 className="super-headline">{RichText.asText(props.panel.pricing_tab.price_1_title)}</h2>
                  <p className="big-body">{RichText.asText(props.panel.pricing_tab.price_1_sub_title)}</p>
                </div>

                {props.panel.pricing_tab.price_2_title && 
                <div className="kb__price--2">
                  <h2 className="super-headline">{RichText.asText(props.panel.pricing_tab.price_2_title)}</h2>
                  <p className="big-body">{RichText.asText(props.panel.pricing_tab.price_2_sub_title)}</p>
                </div>
                }
              </div>
            </div>
          }

          {props.panel.pricing_tab.body &&
            props.panel.pricing_tab.body.map((slice,i) => {
              if(slice.type === "checkmark_list"){
                return(
                  <div key={i} className="kb__list">
                    <div className="kb__grid">
                      {slice.fields.map((item,i) => {
                        return(
                          <div key={i} className="kb__col-6_sm-12">
                            <div className="kb__list-wrap">
                              <InlineSVG url={slice.primary.list_image.url} classes="kb__list-image" />
                              <p className="big-body">{RichText.asText(item.list_text)}</p>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )
              } else if(slice.type === "price_tiers___calc"){

                return(
                  <section className="kb__price-tiers" key={i}>
                    <div className="kb__grid-center">

                      {slice.fields.map((tier,i) => {
                        const price = (tier.base_price.replace('$','') * 1);
                        const rate = (tier.additional_price.replace('%','') / 100 );

                        return(

                          <div className="kb__col-4__md-6__sm-12" key={i}>
                          
                            <div className={`kb__price-tier${pricingSelection === RichText.asText(tier.tier_name) ? " kb__price-tier--active" : ""}`} data-tier={RichText.asText(tier.tier_name)} data-price-per-backer={price} data-rate={rate} onClick={setPricing} onKeyDown={keyPress} role="button" tabIndex="0">

                              <div className="kb__price-recommendation">{RichText.render(tier.tier_recommendation)}</div>
                                
                              <div>
                                {RichText.render(tier.tier_name)}
                                {RichText.render(tier.tier_copy)}
                              </div>

                              <div className="kb__price-details">
                                <div>
                                  <h4>{tier.base_price}</h4>
                                  <p className="kb__body-sm">{tier.base_unit}</p>
                                </div>

                                <div>
                                  <span>+</span>
                                </div>

                                <div>
                                  <h4>{tier.additional_price}</h4>
                                  <p className="kb__body-sm">{tier.additional_text}</p>
                                </div>
                              </div>

                              <div>
                                <div className="kb__price-info">
                                  <img src={IconCheck} alt="Check icon" />
                                  {RichText.render(tier.tier_plus_1)}
                                </div>
                                <div className="kb__price-info">
                                  <img src={IconCheck} alt="Check icon" />
                                  {RichText.render(tier.tier_plus_2)}
                                </div>
                              </div>

                            </div>
                          </div>
                        )
                      })}

                    </div> 
                    <div className="kb__price-cta">
                      {RichText.render(slice.primary.tiers_cta_title)}

                      <a href="/support" title={slice.primary.tiers_cta_text}>
                        {slice.primary.tiers_cta_text}
                      </a>
                    </div>

                    <PlanCalculator />

                    <div className="kb__price-banner">
                      {RichText.render(slice.primary.tier_banner_text)}
                    </div> 

                    <h3 className="kb--center">Features</h3>
                    
                  </section>  
                )
              } else if(slice.type === "fine_print"){
                return(
                  <div className="kb__fine-print kb__body-sm" key={i}>
                    {slice.primary.text &&
                      RichText.render(slice.primary.text)
                    }
                  </div>
                )
              } else {
                return null;
              }
            })
          }

          {props.panel.pricing_tab.price_cta && 
            <div className="kb__actions">
              <a className="kb__button--primary kb__button--large" href={handledLink}>{RichText.asText(props.panel.pricing_tab.price_cta_text)}</a>
            </div>
          }


          {props.panel.pricing_tab.body &&
            props.panel.pricing_tab.body.map((slice,i) => {
              if(slice.type === "faqs"){
                return(
                  
                  <FAQs key={i} faqs={slice} />
                  
                )
              } else {
                return null;
              }
            })
          }
        </div>
      </div>
    </div>
  )
}

export default PricingPanel
